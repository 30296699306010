@use "./scss/abstracts/" as *;

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400&display=swap');
@import url("http://fonts.cdnfonts.com/css/avenir");

// Reset and Base Styles
*{
  margin: 0;
  padding: 0px;
  box-sizing: border-box;
}

body {
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
  background-color: $bodyBg;


  // @include tablet() {
  //   background-color: #1f1f1f;
  // }
}
