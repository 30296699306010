@use "../../scss/abstracts/" as *;

.premiumPage {
  .hero {
    min-height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    background: url('../../assets/ucast_premium_bg.png') no-repeat center center/cover;

    &__heroContent {
      max-width: 900px;
      color: #fff;
      text-align: center;
      position: relative;
      z-index: 2;
      padding: 10px;

      h1{
          color: #F2F2F2;
          margin-bottom: 30px;
      }

      p {
        color: #F2F2F2;

        &.tagline {
          font-family: "Avenir", sans-serif;
          color: #fff !important;
          margin-bottom: 20px;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 2px;
        }
      }

      .flex {
        margin-top: 40px;
        justify-content: space-around;
      }
    }

    &::after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: #151515;
        opacity: .3;
    }
  }

  .plans{
      padding: 130px 0;
      @include container();

      .header{
          text-align: center;
          color: #f2f2f2;
          margin-bottom: 80px;

          h4{
              font-size: 28px;
              margin-bottom: 20px;
          }
      }

      .pricePlans{
        display: flex;
        justify-content: center;
      }
  }

  @include tablet(){
    .hero{
      &__heroContent{
        h1{
          font-size: 2.4rem;
        }

        p{
          font-size: 14px;
        }
      }
    }

    .plans{
      .pricePlans{
        flex-direction: column;
      }
    }
  }
}
