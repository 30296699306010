@use "../../scss/abstracts/" as *;

.pricePlan{
    width: 400px;
    margin-right: 50px;

    &:last-child{
        margin-right: 0;
    }

    &__header{
        position: relative;
        background-color: #303030;
        padding: 60px 30px 30px 30px;
        border-top-right-radius: 35px;
        border-top-left-radius: 35px;
        text-align: center;
        border-bottom-width: 3px;
        border-bottom-style: solid;
        border-image: linear-gradient(to right, rgba(229, 89, 180, 0.5), rgba(34, 46, 239, 0.5));
        border-image-slice: 1;


        h6{
            color: #E559B4;
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 5px;
        }

        p{
            font-size: 12px;
            color: #B9B9B9;
        }

        img{
            width: 130px;
            position: absolute;
            top: -45px;
            left: 30px;
        }
    }

    &__body{
        padding: 50px;
        border-bottom-right-radius: 35px;
        border-bottom-left-radius: 35px;
        background-color: #303030;

        p{
            text-align: center;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 25px;
        }

        ul{
            margin-bottom: 70px;
            
            li{
                font-size: 12px;
                margin-bottom: 15px;
                display: flex;
                align-items: center;


                svg{
                    display: inline-block;
                    margin-right: 25px;
                }
            }
        }

        .ucast-purple-gradient-btn{
            display: block;
            width: 100%;
            padding: 15px;
        }
        
    }

    @include tablet(){
        width: 100%;
        margin-bottom: 100px;
        margin-right: 0;

        &__header{
            img{
                left: 0;
                right: 0;
                margin: auto;
            }
        }

        &__body{
            padding: 20px;

            p{
                text-align: left;
            }
        }
    }
}