.selectionForm {
    color: white;
    font-weight: 600;
    
    .subtext {
        
        font-weight: 400;
        font-size: 0.75em;

    }

    button {
        background-color: #272727;
        border: 1px solid rgba(242, 242, 242, 0.7);

        &:hover {
            background-color: #4c4c4c;
        }
    }
}