@use "../../scss/abstracts/" as *;

.podcastersPage {
  .hero {
    @include heroSection();

    background-image: url("../../assets/pod-bg.png");
    background-repeat: no-repeat;
    background-position-y:  35%;
    background-size: 70%;

    @include tablet(){
      background-size: 100%;
      background-position-y: 22%;
    }
  }

  #steps {
    background: url("../../assets/pod-bg-2.png") no-repeat center center/contain;
  }
}
