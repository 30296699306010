@use "../../scss/abstracts/" as *;

.home {
  .hero {
    position: relative;
    overflow-y: hidden;
    min-height: 100vh;
    display: flex;
    align-items: center;
    background: url("../../assets/ucast_homeBG.png") no-repeat center
      center/cover;
  }

  #podcasts {

    .podcastCard {
      width: 250px;
      height: 250px;
      // border-radius: 27px;
      border: 1px solid #fff;
      display: block;
    }

    .carousel-prev:before{
      background-image: url("../../assets/icons/arrow-left.svg");
      background-size: 50px;
    }
    .carousel-next:before{
      background-image: url("../../assets/icons/arrow-right.svg");
      background-size: 50px;
    }
  }

  // Mobile styles
  @include tablet() {
    .hero {
      min-height: 75vh;
      background: transparent;
    }

    #howWorks {
      top: 0;
    }

    #podcasts {
      background: none;

      .podcastCard{
        width: 100px;
        height: 100px;
      }
    }

    #partners {
      background: none;
      top: 0;
    }
  }
}
