.startForm {
    position: relative;
    min-height: 80vh;
    display: flex;
    align-items: center;

    .progress-text {
        color: #908F8F;
        font-weight: 600;
    }
}