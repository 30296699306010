@use "./variables.scss" as *;

@mixin container {
  max-width: $containerWidth;
  margin: auto;
  color: white;
}

@mixin button() {
  display: inline-block;
  border: none;
  border-radius: $borderRadius;
  font-weight: $fontBold;
  text-align: center;
  font-size: 13px;
  padding: 12px 50px;
  // transition: all 0.3s ease;
  color: #fff;

  &:hover {
    text-decoration: none;
    color: #fff;
    box-shadow: 0 0.5em 0.5em -0.4em $purpleColor;
    transform: translateY(-0.25em);
  }
}

@mixin lightButton {
  @include button();
  color: #fff;
  border: 1px solid #ffffff;
}

@mixin darkButton() {
  @include button();
  background: #2f2f2f;
  border: none;

  span {
    background: -webkit-linear-gradient(#e559b4, #6b71e9);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@mixin purpleGradientButton {
  @include button();
  background: linear-gradient(to right, #e559b4, #335cfb);
  color: #fff;
  padding: 12px 50px;
}

@mixin yellowGradientButton {
  @include button();
  background: linear-gradient(
    to right,
    rgba(255, 203, 51, 0.99),
    rgba(255, 117, 33, 0.92)
  );
  color: #fff;
  padding: 12px 80px;
}

@mixin yellowHollowGradientButton {
  @include button();
  border: 2px solid  rgba(255, 156, 51, 0.99);
  border-radius: 10px;
  padding-left: 6rem;
  padding-right: 6rem;
  
  
  background: linear-gradient(92deg, #FFCB35 37.74%, #FF7622 70.19%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;

  &:hover {
      background-clip: unset;
      -webkit-background-clip: unset;
      -webkit-text-fill-color: black;
      opacity: unset;
  }

  @include tablet(){
    padding-left: 2rem;
    padding-right: 2rem;
  }
}

@mixin purpleGradientText() {
  background-image: linear-gradient(to right, #e559b4 80%, #365cfa 20%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  margin-bottom: 40px;
}

@mixin mobile {
  @media only screen and (max-width: $mobile-width) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: $tablet-width) {
    @content;
  }
}

@mixin heroSection() {
  min-height: 100vh;
  display: flex;
  background-color: #1f1f1f;
  position: relative;

  &__content {
    display: flex;
    // align-items: center;
    max-width: 85vw;
    width: 100%;
    margin: auto;

    .info {
      color: #fff;

      h1 {
        margin-bottom: 25px;
        color: #f2f2f2;
      }

      p {
        margin-bottom: 15px;
        letter-spacing: 3px;
        font-size: 15px;

        &.desc {
          letter-spacing: normal;
          font-size: 14px;
          margin-bottom: 60px;
          color: #f2f2f2;
        }
      }

      .ucast-purple-gradient-btn,
      .ucast-yellow-gradient-btn {
        padding: 12px 100px;
      }
    }

    .image {
      width: 50%;
      position: relative;
      right: -8vw;

      img {
        width: 120%;
        max-width: 150%;
      }
    }
  }

  @include tablet() {
    &__content {
      flex-direction: column;
      max-width: 100vw;
      padding-top: 100px;
      margin: 0;
      justify-content: space-between;

      .info {
        color: #fff;
        width: 50%;
        padding: 20px;
        text-align: center;

        h1 {
          margin-bottom: 30px;
          color: #f2f2f2;
          font-size: 2.4rem;
        }

        p {
          margin-bottom: 20px;
          letter-spacing: 2px;
          font-size: 13px;

          &.desc {
            margin-bottom: 50px;
          }
        }

        .ucast-purple-gradient-btn,
        .ucast-yellow-gradient-btn {
          padding: 15px 100px;
          font-size: 15px;
        }
      }

      .info,
      .image {
        width: 100%;
      }

      .image {
        right: auto;
        padding-bottom: 20px;

        img {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
}

@mixin campaignSection() {
  padding: 60px 0;
  color: #fff;
  background-color: $bodyBg;
  background-repeat: no-repeat;
  background-position-x: left;
  background-position-y: -5px;
  background-size: 48% 105%;

  .content {
    max-width: 85vw;
    min-height: 500px;
    padding: 90px 60px;
    margin: auto;
    display: flex;
    align-items: flex-start;
    border: 2px solid;
    border-radius: 40px;

    .text {
      width: 60%;

      h1 {
        margin-bottom: 50px;
        color: #f2f2f2;
      }

      p.types {
        color: #a5a5a5;
        font-size: 12px;
        letter-spacing: 2px;
        margin-bottom: 20px;
      }
    }

    .img {
      text-align: center;
      margin-bottom: 20px;
      img {
        display: block;
        margin-bottom: 50px;
      }

      .ucast-light-btn {
        padding: 12px 120px;
      }
    }
  }

  @include tablet() {
    padding: 20px;
    .content {
      flex-direction: column;
      max-width: 100vw;
      padding: 60px 20px;
      text-align: center;

      .text,
      .img {
        width: 100%;
      }

      .text {
        margin-bottom: 40px;

        h1 {
          font-size: 1.6rem;
        }

        p.types {
          margin-bottom: 10px;
        }

        // a {
        //   display: none;
        // }
      }
    }
  }
}
