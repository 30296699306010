@use "../../scss/abstracts/" as *;

.footer {
  padding: 70px 0 120px 0;

  &__content {
    max-width: 85vw;
    margin: auto;
    display: flex;
    align-items: flex-start;

    &__logo {
      width: 60%;
    }

    &__links {
      display: flex;

      div {
        margin-right: 100px;

        li {
          margin-bottom: 10px;

          a {
            font-size: 13px;
            margin-bottom: 15px;
            font-weight: 500;
            color: #dfdfdf;

            &:hover {
              color: #fed035;
            }
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
    .social {
      a {
        display: inline-flex;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: #fff;
        align-items: center;
        justify-content: center;
        color: $darkColor;

        &:hover {
          background-color: $darkColor;
          color: #fff;
        }

        &:first-child {
          margin-right: 20px;
        }
      }
    }
  }

  @include tablet() {
    padding: 50px 10px 20px 10px;

    &__content {
      flex-direction: column;

      &__logo {
        width: 100%;
        margin-bottom: 30px;
        display: flex;
        justify-content: space-between;

        img {
          width: 100px;
        }
      }

      &__links {
        justify-content: flex-end;
        width: 100%;
        div {
          margin-right: 50px;
        }
      }

      .social {
        display: flex;
        align-items: center;

        a {
          &:first-child {
            margin-right: 10px;
          }
        }
      }
    }
  }

  @include mobile() {
    &__content {
      &__logo {
        margin-bottom: 10px;
        img {
          width: 100px;
        }
      }

      &__links {
        justify-content: flex-end;
        width: 100%;
        div {
          margin-right: 50px;
        }
      }
    }
  }
}
