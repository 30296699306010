@use "../../scss/abstracts/" as *;

.topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 50px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 999; 

  &.transparent {
    background-color: #020202;
  }

  .overlay {
    display: none;
    position: absolute;
    z-index: 998;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #1f1f1f;
    transform: translateX(200vw);
    transition: all 0.5s ease;
    padding: 50px 30px;

    &.show {
      display: block;
      transform: translateX(0);
    }

    button {
      font-size: 24px;
      font-weight: 800;
      position: absolute;
      top: 45px;
      right: 30px;
    }
  }

  .button {
    display: none;
  }

  @include tablet() {
    padding: 10px;

    .button {
      display: block;
    }
  }
}

.menu {
  display: flex;
  align-items: center;
  list-style: none;


  &.listItems {
    li {
      &:first-child {
        margin-right: 50px;
      }
    }
  }

  @include tablet() {
    display: none;
  }
}
