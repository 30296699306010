@use "./variables.scss" as *;
@use "./mixins.scss" as *;

.ucast-purple-gradient-btn {
  @include purpleGradientButton();
}

.ucast-yellow-gradient-btn {
  @include yellowGradientButton();
}

.ucast-yellow-hollow-gradient-btn {
  @include yellowHollowGradientButton;
}

.ucast-light-btn {
  @include lightButton();
}

.campaignTypes {
  background-color: $bodyBg;
  display: flex;
  justify-content: center;
  padding-bottom: 50px;

  button {
    display: inline-block;
    position: relative;
    font-weight: 500;
    margin-right: 40px;
    font-size: 15px;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      color: $purpleColor;
    }

    &.current {
      &::before {
        content: "";
        position: absolute;
        border-bottom: 3px solid #e559b4;
        bottom: -10px;
        display: block;
        width: 50%;
        left: 25%;
      }
    }
  }

  @include tablet(){
    padding-top: 50px;
  }
}
